import React, { Component, PropTypes } from 'react';
import '../styles/header.css';

class Checkbox extends Component {
  state = {
    isChecked: false,
  }

  toggleCheckboxChange = () => {
    const { handleCheckboxChange, label } = this.props;

    this.setState(({ isChecked }) => (
      {
        isChecked: !isChecked,
      }
    ));

    handleCheckboxChange(label);
  }

  render() {
    const { label } = this.props;
    const { isChecked } = this.state;

    return (
      

        <div className="col-cat">

        
      <div className="checkbox">
          <p>
          <input
                            type="checkbox"
                            value={label}
                            checked={isChecked}
                            onChange={this.toggleCheckboxChange}
                        />
                        <br></br>
                         <label className="inter-regular label-checkbox" for="leite">{label}</label>

                        
                        </p>
          
                        </div>

       
     
    

      </div>
    );
  }
}



export default Checkbox;
