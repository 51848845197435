import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Headroom from "react-headroom"
import $ from 'jquery' // important: case sensitive.
import CloseIcon from '@material-ui/icons/Close';
import scrollTo from 'gatsby-plugin-smoothscroll';
import react, {Component} from 'react';
import axios from 'axios';
import '../styles/header.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from './Checkbox';
import Tipo from './Tipo';

const items = [
    'Bovinos de Carne',
    'Bovinos de Leite',
    'Aves'
    
  ];
  const items2 = [
    'Pequenos Ruminantes',
    'Suinos',
    'Coelhos'
    
  ];
  const tipos = [
    'Gestor',
    'Produtor',
    'Veterinario'
    
  ];
  const tipos2 = [
   
    'Eng. Zootecnico',
    'Tecnico de Prod. Animal',
    'Outra'
    
  ];
export default class Form extends Component{
    componentWillMount = () => {
        this.selectedCheckboxes = new Set();
        this.selectedTipos = new Set();
      }
      toggleCheckbox = label => {
        if (this.selectedCheckboxes.has(label)) {
            
          this.selectedCheckboxes.delete(label);
        } else {
          this.selectedCheckboxes.add(label);
        }
      }
      toggleTipo = label => {
        if (this.selectedTipos.has(label)) {
            
          this.selectedTipos.delete(label);
        } else {
          this.selectedTipos.add(label);
        }
      }
      
      createTipo = label => (

        <Tipo
                label={label}
                handleTipoChange={this.toggleTipo}
                key={label}
            />
      )
      createTipos = () => (
        tipos.map(this.createTipo)
      )
      createTipos2 = () => (
        tipos2.map(this.createTipo)
      )
      createCheckbox = label => (

        <Checkbox
                label={label}
                handleCheckboxChange={this.toggleCheckbox}
                key={label}
            />
      )
    
      createCheckboxes = () => (
        items.map(this.createCheckbox)
      )
      createCheckboxes2 = () => (
        items2.map(this.createCheckbox)
      )

    state={
        name:'',
        phone:'',
        email:'',
        subject:'',
        message:'',
        sent:false
    }
 
    handleName=(e)=>{
        this.setState({
            name:e.target.value
        })
    }
   
        
    
    handlePhone=(e)=>{
        this.setState({
            phone:e.target.value
        })
    }
    handleEmail=(e)=>{
        this.setState({
            email:e.target.value
        })
    }
  
    handleSubject=(e)=>{
        this.setState({
            subject:e.target.value
        })
    }
    handleMessage=(e)=>{
        this.setState({
            message:e.target.value
        })
    }
    handleCheckbox=(e)=>{
        this.setState({
            checkbox:e.target.value
        })
    }
    

    submitForm = (e) => {
        e.preventDefault();
       
        for (const checkbox of this.selectedCheckboxes) {
            
          console.log(checkbox, 'is selected.');

        }

        for (const checkboxtipos of this.selectedTipos) {
            
            console.log(checkboxtipos, 'is selected.');

          }
        
      
        let sucesso = {}
        sucesso.name = "Pedido confirmado. Obrigado"
        toast.success(`${sucesso.name}`, {
        position: toast.POSITION.TOP_RIGHT
             });
        const {name, email, phone} = this.state;

        const checkbox = this.selectedCheckboxes;
        var it = checkbox. values();
        var first = it. next();
        var second = it. next();
        var third = it. next();
        var four = it. next();
        var five = it. next();
        var six = it. next();
        var carne= first. value;
        var leite = second. value;
        var aves = third. value;
        var ruminantes = four. value;
        var suinos = five. value;
        var coelhos = six. value;

        const tipo = this.selectedTipos;
        var ittipo = tipo. values();
        var firsttipo = ittipo. next();
        var secondtipo = ittipo. next();
        var thirdtipo = ittipo. next();
        var fourtipo = ittipo. next();
        var fivetipo = ittipo. next();
        var gestor= firsttipo. value;
        var produtor = secondtipo. value;
        var vet = thirdtipo. value;
        var zoo = fourtipo. value;
        var tecnico = fivetipo. value;
      
        const tiposArray =[];
        const catsArray =[];
        catsArray.push(carne, leite, aves, ruminantes, suinos, coelhos);
        tiposArray.push(gestor, produtor, vet, zoo, tecnico);

        var arrayFiltrado = catsArray.filter(function(x) {
            return x !== undefined;
       });
       var arraytiposFiltrado = tiposArray.filter(function(x) {
        return x !== undefined;
   });
   let tiposfinais = arraytiposFiltrado.toString();
       let catgeoriasfinais = arrayFiltrado.toString();
       console.log(catgeoriasfinais, 'ELEMENTOS FINAIS');
       console.log(tiposfinais, 'TIPOS FINAIS');



        // console.log(categorias, 'CATEGORIAS');
        
        const key = "d4y4ehg678ve5rbve4$2edfA";
        // create a new XMLHttpRequest
        
        var xhr = new XMLHttpRequest();
    
        // get a callback when the server responds
        xhr.addEventListener('load', () => {
            // update the response state and the step
            
            this.setState ({
                emailStatus: xhr.responseText
            });
        });
        // open the request with the verb and the url
        xhr.open('POST', 'https://www.farmin-trainings.net/api/index.php');

        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                                
        xhr.send('sendto='+email+'&name='+name+'&categorias='+catgeoriasfinais+'&tipos='+tiposfinais+'&phone='+phone+'&k='+key);                                


        // reset the fields
        this.setState({
            name: '',
            email: '',
            phone: ''
            
            
        });
        e.preventDefault();
    }

    //resetar
    resetForm=()=>{
        this.setState({
            name:'',
            phone:'',
            email:''
           
            
           
        })
        
        setTimeout(()=>{
            this.setState({
                sent:false,
            })
        }, 3000)
    }

    render() {

        return (
            <div id="contact">
                <div className="titulo-form inter-bold">
                    Receba informação sobre os cursos FarmIN
                </div>
                <div className="desc inter-regular">
                    Ao submeter o formulário abaixo recebe informação sobre os cursos a decorrer, próximos cursos e respetivo conteúdo programático.
                </div>
            <form onSubmit={this.submitForm} >
            
             <fieldset>
              <input className="inter-regular" placeholder="Nome" type="text" name="name" id="name" required value={this.state.name} onChange={this.handleName} />
              </fieldset>
              <fieldset>
              <input className="inter-regular" placeholder="E-mail"  type="email" name="email" id="email" value={this.state.email} onChange={this.handleEmail} />
              </fieldset>
               <fieldset>
              <input className="inter-regular" placeholder="Telemóvel"  type="tel" name="phone" id="phone" value={this.state.phone} onChange={this.handlePhone}  />
              </fieldset> 
          
             
              <div className="categorias">
              <div className="desc inter-regular">
                    Escolha a(s) área(s) de curso que pretende ser notificado.
                </div>
                  
            {/* <div className="linha2">

            <div className="col-cat">

            <fieldset>
              <input type="checkbox" id="suinos" name="suinos[]" value="suinos"/>
                <label className="inter-regular" for="suinos">Suínos</label>

            </fieldset> 
            </div>
           
            <div className="col-cat">

            <fieldset>
              <input type="checkbox" id="peqrumi" name="peqrumi[]" value="peqrumi"/>
                <label className="inter-regular" for="peqrumi">Peq. Ruminantes</label>

            </fieldset> 
            </div>
            <div className="col-cat">

            <fieldset>
              <input type="checkbox" id="coelhos" name="coelhos[]" value="coelhos"/>
                <label className="inter-regular" for="coelhos">Coelhos</label>
           
            </div>
            </div> */}
             <div className="contentor-checkbox">
            {this.createCheckboxes()}
            
            </div>
            <div className="contentor-checkbox2">
            {this.createCheckboxes2()}
            </div>
            <div className="desc inter-regular">
                    Indique a sua formação profissional.
                </div>
            <div className="contentor-tipos">
            {this.createTipos()}
            </div>
            <div className="contentor-tipos">
            {this.createTipos2()}
            </div>
            </div>
            
            <button className="submitBtn inter-regular" type="submit" value="submit">Receber Informação</button>
            <ToastContainer/>
         
          </form>

          </div>
        )
    }

}

